import React, { useEffect } from "react";
import css from "./Registration.module.scss";
import { Link } from "react-router-dom";
import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Modal from "../../components/UI/Modal/Modal";
import check from "../../assets/icons/confirm.svg";
import Button from "../../components/UI/Button/Button";
import { useTranslation } from "react-i18next";

const Registration = () => {
  const { t, i18n } = useTranslation();
  const errorList = useSelector((store) => store.registration.errors);
  const loading = useSelector((store) => store.registration.loading);
  const completed = useSelector((store) => store.registration.completed);

  const dispatch = useDispatch();

  const { handleSubmit, register, reset, errors } = useForm();
  const onSubmit = (values) => {
    dispatch(actions.newUserRequest(values, i18n.language));
  };

  useEffect(() => {
    return () => clearUserRequestHandler();
  }, []);

  const clearUserRequestHandler = () => {
    reset();
    dispatch(actions.clearUserRequest());
  };

  return (
    <div className="container">
      <div className={css.registration}>
        <p>
          {t("login.no account")}{" "}
          <Link className="simple-link" to="/login">
            {t("login.click for login")}
          </Link>
        </p>
        <h1>{t("login.account request")}</h1>
        <h3>{t("login.general informations")}</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={css.row}>
            <div className={css.col}>
              <label htmlFor="name">{t("forms.name")}*</label>
              <input
                type="text"
                id="name"
                name="name"
                ref={register({
                  required: t("forms.name required"),
                })}
              />
              <span className={css.error}>
                {errorList?.find((x) => x.param === "name")?.msg}
                {errors?.name?.message}
              </span>
            </div>
            <div className={css.col}>
              <label htmlFor="name">{t("forms.surname")}*</label>
              <input
                type="text"
                id="surname"
                name="surname"
                ref={register({
                  required: t("forms.surname required"),
                })}
              />
              <span className={css.error}>
                {errorList?.find((x) => x.param === "surname")?.msg}
                {errors?.surname?.message}
              </span>
            </div>
            <div className={css.col}>
              <label htmlFor="company">{t("forms.company")}*</label>
              <input
                type="text"
                id="company"
                name="company"
                ref={register({
                  required: t("forms.company required"),
                })}
              />
              <span className={css.error}>
                {errorList?.find((x) => x.param === "company")?.msg}
                {errors?.company?.message}
              </span>
            </div>
          </div>
          <h3>{t("login.contact informations")}</h3>
          <div className={css.row}>
            <div className={css.colSmall}>
              <label htmlFor="name">{t("forms.phone")}</label>
              <input type="number" id="phone" name="phone" ref={register()} />
              <span className={css.error}>
                {errorList?.find((x) => x.param === "phone")?.msg}
                {errors?.phone?.message}
              </span>
            </div>
            <div className={css.colSmall}>
              <label htmlFor="email">{t("forms.email")}*</label>
              <input
                type="email"
                id="email"
                name="email"
                ref={register({
                  required: t("forms.email required"),
                })}
              />
              <span className={css.error}>
                {errorList?.find((x) => x.param === "email")?.msg}
                {errors?.email?.message}
              </span>
            </div>
            <div className={css.colSmall}>
              <label htmlFor="country">{t("forms.country")}</label>
              <input
                type="text"
                id="country"
                name="country"
                ref={register({
                  // required: t("forms.country required"),
                })}
              />
              <span className={css.error}>
                {errorList?.find((x) => x.param === "country")?.msg}
                {errors?.country?.message}
              </span>
            </div>
            <div className={css.colSmall}>
              <label htmlFor="address">{t("forms.address")}</label>
              <input
                type="text"
                id="address"
                name="address"
                ref={register({
                  //required: t("forms.address required"),
                })}
              />
              <span className={css.error}>
                {errorList?.find((x) => x.param === "address")?.msg}
                {errors?.address?.message}
              </span>
            </div>
            <div className={css.colSmall}>
              <label htmlFor="street_number">{t("forms.street number")}</label>
              <input
                type="text"
                id="street_number"
                name="street_number"
                ref={register({
                  //required: t("forms.street number required"),
                })}
              />
              <span className={css.error}>
                {errorList?.find((x) => x.param === "street_number")?.msg}
                {errors?.street_number?.message}
              </span>
            </div>
            <div className={css.colSmall}>
              <label htmlFor="city">{t("forms.city")}</label>
              <input
                type="text"
                id="city"
                name="city"
                ref={register({
                  //required: t("forms.city required"),
                })}
              />
              <span className={css.error}>
                {errorList?.find((x) => x.param === "city")?.msg}
                {errors?.city?.message}
              </span>
            </div>
            <div className={css.colSmall}>
              <label htmlFor="province">{t("forms.province")}</label>
              <input
                type="text"
                id="province"
                name="province"
                ref={register({
                  //required: t("forms.province required"),
                })}
              />
              <span className={css.error}>
                {errorList?.find((x) => x.param === "province")?.msg}
                {errors?.province?.message}
              </span>
            </div>
            <div className={css.colSmall}>
              <label htmlFor="cap">{t("forms.cap")}</label>
              <input
                type="text"
                id="cap"
                name="cap"
                ref={register({
                  // required: t("forms.cap required"),
                })}
              />
              <span className={css.error}>
                {errorList?.find((x) => x.param === "cap")?.msg}
                {errors?.cap?.message}
              </span>
            </div>
          </div>
          <div className={css.row}>
            <div className={css.colPrivacy}>
              <input
                type="checkbox"
                name="privacy"
                id="privacy"
                ref={register({
                  required: t("forms.privacy required"),
                })}
              />
              <label htmlFor="privacy">
                Ai sensi e per gli effetti degli articoli 7 e 13 del Reg. UE
                n°679/2016, dichiaro di aver preso conoscenza dell'informativa
                di Tessitura Attilio Imperiali per il trattamento dei miei dati
                personali, di essere esaurientemente informato in merito ai miei
                diritti e di conoscere come e dove esercitare gli stessi,
                pertanto per consenso e presa visione. Acconsento al trattamento
                delle informazioni rilasciate per le finalità indicate nella
                predetta informativa.
              </label>
            </div>
            <span className={css.error} style={{ paddingLeft: 20 }}>
              {errorList?.find((x) => x.param === "privacy")?.msg}
              {errors?.privacy?.message}
            </span>
          </div>
          <div className={css.submitCol}>
            <Button text={t("forms.account request")} load={loading} />
            <p>{t("forms.registration asterisk")}</p>
          </div>
        </form>
      </div>
      <Modal open={completed}>
        <div className={css.modalContent}>
          <img src={check} alt="" />
          <h3>{t("login.registration confirm")}</h3>
          <p>{t("login.registration confirm message")}</p>
          <Button
            secondary
            text={t("general.ok")}
            onClick={() => clearUserRequestHandler()}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Registration;
