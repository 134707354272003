import React from "react";
import css from "./Button.module.scss";
import Spinner from "../Spinner/Spinner";
//import addIcon from "../../../assets/icons/wishlist-add.svg";

const Button = (props) => {
  const { fullwidth, secondary, load, icon, text, onClick } = props;

  return (
    <button
      onClick={onClick}
      className={`${css.button} ${fullwidth && css.fullwidth} ${
        secondary ? css.secondary : css.primary
      }`}
      disabled={load && true}
    >
      {load ? (
        <Spinner white />
      ) : (
        <>
          {icon && (
            <img
              style={{ marginRight: "15px", width: "20px" }}
              src={icon}
              alt=""
            />
          )}{" "}
          {text}
        </>
      )}
    </button>
  );
};

export default Button;
