import React from "react";
import css from "./FeaturedProducts.module.scss";
import { Link } from "react-router-dom";
import path from "../../util/path";
import { useTranslation } from "react-i18next";

const FeaturedProducts = (props) => {
  const { t } = useTranslation();
  const { products } = props;
  return (
    <div className={css.featuredWrap}>
      {products && products.length >= 1 && (
        <>
          <div className={`${css.col} ${css.col1}`}>
            <div className={css.blueBox}>
              <div className={css.blueBoxContent}>
                <h4>Classici</h4>
                <Link to={{ pathname: "/search", query: { collectionId: 1 } }}>
                  {t("home.see all")}
                </Link>
              </div>
            </div>
            <div className={css.prodWrap}>
              {products[0] && (
                <Link to={`product/${products[0].url}`} className={css.prodBox}>
                  <img src={path + products[0].image} alt="" />
                  <div className={css.prodContent}>
                    <span>{products[0]?.code}</span>
                    <h4>{products[0]?.name}</h4>
                  </div>
                </Link>
              )}
              {products[1] && (
                <Link to={`product/${products[1].url}`} className={css.prodBox}>
                  <img src={path + products[1].image} alt="" />
                  <div className={css.prodContent}>
                    <span>{products[1]?.code}</span>
                    <h4>{products[1].name}</h4>
                  </div>
                </Link>
              )}
            </div>
          </div>
          <div className={`${css.col} ${css.col2}`}>
            {products[2] && (
              <Link to={`product/${products[2].url}`} className={css.prodBox}>
                <img src={path + products[2].image} alt="" />
                <div className={css.prodContent}>
                  <span>{products[2]?.code}</span>
                  <h4>{products[2].name}</h4>
                </div>
              </Link>
            )}
          </div>
          <div className={`${css.col} ${css.col3}`}>
            {products[3] && (
              <Link to={`product/${products[3].url}`} className={css.prodBox}>
                <img src={path + products[3].image} alt="" />
                <div className={css.prodContent}>
                  <span>{products[3]?.code}</span>
                  <h4>{products[3].name}</h4>
                </div>
              </Link>
            )}
            {products[4] && (
              <Link to={`product/${products[4].url}`} className={css.prodBox}>
                <img src={path + products[4]?.image} alt="" />
                <div className={css.prodContent}>
                  <span>{products[4]?.code}</span>
                  <h4>{products[4].name}</h4>
                </div>
              </Link>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default FeaturedProducts;
