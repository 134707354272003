import React, { useState } from "react";
import css from "./Header.module.scss";
import { Link, useHistory } from "react-router-dom";
import logo from "./../../assets/images/logo.svg";
import userIcon from "../../assets/icons/account-white.svg";
import likeIcon from "../../assets/icons/wishlist-white.svg";
import searchIcon from "../../assets/icons/search.svg";
import searchIconDark from "../../assets/icons/searchDark.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Header = () => {
  const { t } = useTranslation();
  const [searchValue, setSerachValue] = useState("");
  const token = useSelector((store) => store.auth.token);

  const history = useHistory();

  const onSearchHandler = (e) => {
    e.preventDefault();
    history.push(`/search/${searchValue}`);
  };

  return (
    <header className={css.header}>
      <div className="container">
        <div className={css.headerContent}>
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
          <div className={css.rightSide}>
            {token && (
              <form
                onSubmit={(e) => onSearchHandler(e)}
                className={css.searchWrap}
              >
                <button>
                  <img src={searchIcon} className={css.desk} alt="" />
                  <img src={searchIconDark} className={css.mob} alt="" />
                </button>
                <input
                  type="text"
                  value={searchValue}
                  onChange={(event) =>
                    setSerachValue(event.target.value.replace("#", ""))
                  }
                />
              </form>
            )}
            <Link to="/profile" className={css.box}>
              <img src={userIcon} alt="" />
              <span>{t("general.account")}</span>
            </Link>
            <Link to="/my-list" className={css.box}>
              <img src={likeIcon} alt="" />
              <span>{t("header.my list")}</span>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
