import React from "react";
import ReactDOM from "react-dom";
import "./style.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import registrationReducer from "./store/reducers/registration";
import errorReducer from "./store/reducers/error";
import authReducer from "./store/reducers/auth";
import userReducer from "./store/reducers/user";
import productReducer from "./store/reducers/product";
import wishlistReducer from "./store/reducers/wishlist";
import orderReducer from "./store/reducers/order";
import tableReducer from "./store/reducers/table";
import searchReducer from "./store/reducers/search";
import axios from "axios";
import path from "./util/path";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/translation.json";
import translationIT from "./locales/it/translation.json";
import translationFR from "./locales/fr/translation.json";
import filtersReducers from "./store/reducers/filters";
import HttpsRedirect from "react-https-redirect";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = combineReducers({
  registration: registrationReducer,
  auth: authReducer,
  user: userReducer,
  product: productReducer,
  wishlist: wishlistReducer,
  order: orderReducer,
  table: tableReducer,
  search: searchReducer,
  filters: filtersReducers,
  error: errorReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

axios.defaults.baseURL = path + "api/";

axios.interceptors.request.use((config) => {
  const token = store.getState().auth.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const history = createBrowserHistory();

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    fallbackLng: "en",
    resources: {
      en: {
        translation: translationEN,
      },
      it: {
        translation: translationIT,
      },
      fr: {
        translation: translationFR,
      },
    },
  });

ReactDOM.render(
  <React.StrictMode>
    <HttpsRedirect>
      <Provider store={store}>
        <Router history={history} basename={process.env.PUBLIC_URL}>
          <App />
        </Router>
      </Provider>
    </HttpsRedirect>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
