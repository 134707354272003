import * as actionTypes from "../actions/actionTypes";

const initialState = {
  token: null,
  userId: null,
  wishlistId: null,
  loading: false,
  error: null,
  lostPasswordLoad: false,
  lostPasswordError: null,
  lostPasswordCompleted: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.LOGIN_SUCCESS:
      return {
        loading: false,
        token: action.token,
        userId: action.user,
        wishlistId: action.wishlist,
        error: null,
      };

    case actionTypes.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.token,
        userId: action.user,
        wishlistId: action.wishlist,
      };

    case actionTypes.LOGOUT:
      return {
        ...state,
        token: null,
        userId: null,
        wishlistId: null,
      };

    case actionTypes.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case actionTypes.LOST_PASSWORD_LOAD:
      return {
        ...state,
        lostPasswordLoad: true,
      };

    case actionTypes.LOST_PASSWORD_SUCCESS:
      return {
        ...state,
        lostPasswordLoad: false,
        lostPasswordCompleted: true,
      };

    case actionTypes.LOST_PASSWORD_ERROR:
      return {
        ...state,
        lostPasswordLoad: false,
        lostPasswordError: action.error,
      };

    case actionTypes.CLEAR_LOST_PASSWORD_COMPLETED:
      return {
        ...state,
        lostPasswordCompleted: false,
      };

    default:
      return state;
  }
};

export default authReducer;
